//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatDateShort } from '@/helpers/dateTime'
import { quoteSignNumberFormat } from '@/helpers/number'
export default {
  name: 'HomeCalendarCollapse',
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  methods: {
    formatDateShort,
    quoteSignNumberFormat,
    categoryType (type) {
      switch (type) {
        case 'XD':
          return this.$t('calendar.benefit.dividend')
        case 'XD(ST)':
          return this.$t('calendar.benefit.dividendStock')
        // case 'XT' || 'XW' || 'XR':
        //   return '{{ $t('calendar.benefit.ratioOldNew') }} ณ ราคา'
        // case 'XE':
        //   return 'ราคาแปลงสภาพ'
        // case 'XM':
        //   return this.$t('calendar.benefit.agenda')
        // case 'XN':
        //   return 'เงินคืน'
        // case 'XB':
        //   return this.$t('calendar.benefit.subscriptionPrice')
        default:
          return ''
      }
    }
  }
}
