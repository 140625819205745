//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  generatorXTypeFontColor,
  generatorXTypeColor,
  generatorXTypeColorHead,
  generatorXTypeCardColor
} from '@/helpers/utilities'
export default {
  name: 'CalendarActivity',
  props: {
    dayItem: {
      type: Object,
      default: Object
    },
    loading: {
      type: Boolean
    }
  },
  data () {
    return {
      active: '',
      corporateContent: [],
      corporateItem: null,
      corporateItemSymbol: '',
      corporateItemCaType: '',
      corporateItemCaTypeShow: '',
      currentTab: 'สิทธิประโยชน์'
    }
  },
  computed: {
    customData () {
      return this.dayItem.customData || null
    },
    typesList () {
      return this.customData ? this.customData.types || [] : []
    },
    meetingList () {
      return this.customData ? this.customData.meetings || [] : []
    },
    defaultType () {
      return this.typesList ? this.typesList[0].type : ''
    },
    activeClass () {
      return type => type === this.active
    }
  },
  watch: {
    dayItem () {
      this.activeTab()
      if (this.typesList && this.typesList.length) {
        this.changeTypes(this.defaultType)
      } else {
        this.corporateContent = []
      }
    }
  },
  mounted () {
    this.activeTab()
  },
  methods: {
    generatorXTypeColor,
    generatorXTypeColorHead,
    generatorXTypeFontColor,
    generatorXTypeCardColor,
    changeTypes (type) {
      if (this.typesList && this.typesList.length) {
        this.active = type
        this.corporateContent =
          this.typesList.find(item => item.type === type)?.corporateActions ||
          []
      }
    },
    changeCorporate (item) {
      this.corporateItem = item
      this.corporateItemSymbol = item.symbol
      this.corporateItemCaType = item.type
      this.corporateItemCaTypeShow =
        item.caType === 'XD(ST)' ? 'XD (ST)' : item.caType
      this.$refs.modalCorporate.show()
    },
    changeMeeting (item) {
      this.corporateItem = item
      this.corporateItemSymbol = item.symbol
      this.corporateItemCaType = item.type
      this.corporateItemCaTypeShow =
        item.caType === 'XD(ST)' ? 'XD (ST)' : item.caType
      this.$refs.modalMeeting.show()
    },
    onClose () {
      this.$refs.modalCorporate.hide()
    },
    activeTab () {
      if (this.currentTab === 'สิทธิประโยชน์') {
        if (this.typesList && this.typesList.length > 0 && this.meetingList && this.meetingList.length > 0) {
          this.currentTab = 'สิทธิประโยชน์'
        } else if (this.typesList && this.typesList.length > 0) {
          this.currentTab = 'สิทธิประโยชน์'
        } else if (this.meetingList && this.meetingList.length > 0) {
          this.currentTab = 'การจัดประชุม'
        }
      } else if (this.currentTab === 'การจัดประชุม') {
        if (this.typesList && this.typesList.length > 0 && this.meetingList && this.meetingList.length > 0) {
          this.currentTab = 'การจัดประชุม'
        } else if (this.meetingList && this.meetingList.length > 0) {
          this.currentTab = 'การจัดประชุม'
        } else if (this.typesList && this.typesList.length > 0) {
          this.currentTab = 'สิทธิประโยชน์'
        }
      }
    },
    eventTrackingCorporate (symbol) {
      this.$personalized.clickElement({
        name: 'home22',
        optionLang: {
          type: this.active,
          symbol
        }
      })
    },

    eventTrackingMeeting (symbol) {
      this.$personalized.clickElement({
        name: 'home23',
        optionLang: {
          symbol
        }
      })
    }
  }
}
