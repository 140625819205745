//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Message',
  props: {
    msgType: {
      type: String,
      default: 'empty' // empty | error
    },
    msgTitle: {
      type: String,
      default: null
    },
    msgTitleSearch: {
      type: Boolean,
      default: null
    }
  },
  data () {
    return {
      imgEmpty: require('@/static/images/icons/msg/empty.png')
    }
  }
}
