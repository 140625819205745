//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatTimeLongWithSpace } from '@/helpers/dateTime'
export default {
  name: 'HomeCalendarCollapseMeeting',
  props: {
    item: {
      type: Object,
      default: null
    },
    isStockCalendar: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    formatTimeLongWithSpace
  }
}
